import React from 'react';
import styles from './promotions.module.sass';
import { Text } from '../../Text';
import { Promotion } from './components';
import { useEmblaSlider } from '../../../hooks/useEmblaSlider';
import { Dots } from '../../Dots';
import { YaAndGaMainPage } from '../../../lib/YaAndGaHelper';

const discounts = [
  {
    title: 'Акции на 2024 год',
    photo: '/images/kkk/11.jpg',
    percent: '20',
    href: '/discount',
  },
  {
    title: 'Лучшее предложение дня!',
    photo: '/images/kkk/12.jpg',
    percent: '16',
    href: '/discount/best_day',
  },
  // {
  //   title: 'Кэшбэк 20%',
  //   photo: '/images/kkk/10.jpg',
  //   percent: '15',
  //   href: '/cashback',
  // },
  {
    title: 'Раннее бронирование',
    photo: '/images/kkk/1.jpg',
    percent: '10',
    href: '/discount/rannee_bron',
  },
  {
    title: 'Медицинским работникам',
    photo: '/images/kkk/2.jpg',
    percent: '20',
    href: '/discount/discount_medics',
  },
  {
    title: 'Пенсионерам и ветеранам',
    photo: '/images/kkk/3.jpg',
    percent: '10',
    href: '/discount/discount_pensioners',
  },
  {
    title: 'Семьям с детьми',
    photo: '/images/kkk/4.jpg',
    percent: '15',
    href: '/discount/discount_children',
  },
  {
    title: 'Молодым семьям и супругам-юбилярам',
    photo: '/images/kkk/5.jpg',
    percent: '10',
    href: '/discount/discount_married',
  },
  {
    title: 'Именинникам',
    photo: '/images/kkk/6.jpg',
    percent: '10',
    href: '/discount/discount_birthday',
  },
];

export const Promotions = ({ isMobile }) => {
  const { ref, current, onSelectSlide } = useEmblaSlider({
    align: 'start',
    dragFree: true,
  });
  return (
    <section>
      <div className={styles.head}>
        <Text variant="heading-1" component="h2">
          Все скидки и акции от операторов
        </Text>
      </div>
      <div className={styles.list}>
        {discounts.map(({ href, title, photo, percent }, index) => (
          <Promotion
            key={`${title}-${index}`}
            title={title}
            img={`${photo}`}
            link={href}
            isMobile={isMobile}
            percent={percent}
          />
        ))}
      </div>
      <div className={styles.embla} ref={ref}>
        <div className={styles.emblaContainer}>
          {discounts.map(({ href, title, photo, percent }) => (
            <div key={title} className={styles.emblaSlide}>
              <Promotion
                title={title}
                img={`${photo}`}
                link={href}
                isMobile={isMobile}
                percent={percent}
                onClick={() => {
                  YaAndGaMainPage('click_card_sale');
                }}
              />
            </div>
          ))}
        </div>
        <Dots
          view="dynamicWidth"
          count={discounts.length}
          active={current}
          onSelect={onSelectSlide}
        />
      </div>
    </section>
  );
};
