import React from 'react';
import styles from './promotion.module.sass';
import { Text } from '../../../Text';

export const Promotion = ({ img, title, link, isMobile, percent }) => (
  <a className={styles.item} href={link} rel="noreferrer">
    {' '}
    {title === 'Акции на 2024 год' && (
      <div className={styles.percent}>
        <div className={styles.percentLeft}>
          <Text variant="textShadowSmall" colorText="white">
            до{' '}
          </Text>
        </div>
        <Text variant="textShadow" colorText="white">
          {percent}%
        </Text>
      </div>
    )}
    <img
      src={img}
      width={128}
      height={128}
      className={styles.img}
      alt=""
      loading={'lazy'}
    />
    <p className={styles.title}>{title}</p>
  </a>
);
